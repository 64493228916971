import React, { useEffect } from "react";
import { home } from "../data/dummydata";
import Typewriter from "typewriter-effect";

export const Hero = () => {
  return (
    <>
      <section className="hero">
        {home.map((val, i) => (
          <div className="heroContent">
            <h3 className="fontSize" data-aos="fade-right">
              {val.text}
            </h3>
            <div className="typewriter">
              <h1>
                <Typewriter
                  options={{
                    strings: [`${val.name}`, `${val.design}`, `${val.role}`],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>{" "}
            </div>
            <p data-aos="fade-left">{val.desc}</p>
            <a
              href="Maurilio-Saddoud-Resume.pdf"
              target="_blank"
            >
              <button className="primaryBtn" data-aos="fade-up-right">
                Download CV
              </button>
            </a>
          </div>
        ))}
      </section>
    </>
  );
};
