import { Grid } from "@mui/material";
import React from "react";
import { Heading } from "../common/Heading";
import { services } from "../data/dummydata";

export const Services = () => {
  return (
    <>
      <section className="services">
        <div className="container">
          <Heading title="Skills" className="heading" />
          <Grid className="content" container spacing={2}>
            {services.map((item) => (
              <Grid item xs={12} sm={6} md={4}>
                <div className="box" data-aos="flip-left">
                  <i>{item.icon}</i>
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </>
  );
};
